@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Rubik', sans-serif;
}

html, body {
    width: 100%;
    height: 100%;
    background-color: #F2F2F2;
    scroll-behavior: smooth;
}

body {
    @media (min-width: 1001px) {
        overflow-y: scroll !important;
    }
    @media (max-width: 1000px) {
        overflow-x: hidden;
    }
}

#root, #root > div {
    width: 100%;
}

h1, h2, h3, p {
    color: #1c1c1c;
}